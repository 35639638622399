.formPair {
	display: flex;
}

.formPair div {
	flex-grow: 1;
}

.formGrid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 12px 10px;
}

.formSection {
	padding-bottom: 30px;
}

.formSection .Polaris-Caption {
	margin-bottom: 15px;
}

.dcPadding12 {
	padding-top: 12px;
}

.copyCaption {
	font-size: 10pt;
	margin-bottom: 10px;
}

@media (min-width: 639px) {
	.modalHalfLeft, .modalHalfRight {
		width: 50%;
		float: left;
		margin-bottom: 20px;
	}
	
	.modalHalfLeft {
		padding-right: 20px;
	}
	
	.modalHalfRight{
		padding-left: 20px;
		border-left: .1rem solid #dfe3e8;
	}
}